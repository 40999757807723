<template>
    <li class="tic-item">
        <h3 class="tic-title">
            <span class="tic-title__date">{{ prettyDateTime.date }}</span>
            <span class="tic-title__time">{{ prettyDateTime.time }}</span>
        </h3>

        <p class="tic-content">
            <Button as="a" :href="srcUrl" target="_blank" class="tic-download">Download</Button>
            <Button @click="modal = true" href="#" class="tic-view" variant="outline">
                {{ isVideo ? 'Play' : 'View' }}
            </Button>

            <template v-if="story.locations">
                <span v-b-tooltip title="Location information available" variant="outline-success">
                    <font-awesome-icon fixed-width icon="map-marked-alt"/>
                </span>
            </template>
        </p>

        <b-modal v-model="modal" :ok-disabled="true">
            <template v-slot:modal-title>
                {{ prettyDateTime.date }} at {{ prettyDateTime.time }}
            </template>

            <template v-if="isVideo">
                <vue-plyr hide-controls="false">
                    <video :src="srcUrl"/>
                </vue-plyr>
            </template>

            <template v-else>
                <b-img :src="srcUrl" :fluid="true"/>
            </template>

            <template v-slot:modal-footer></template>
        </b-modal>
    </li>
</template>

<script>
    import localDatetime from '../../utils/mixins/localDatetime'
    import Button from "@/components/pfp/ui/button/Button.vue";
    import Badge from "@/components/pfp/ui/badge/Badge.vue";
    import useTheme from "@/composables/useTheme";
    import { last } from 'lodash'

    export default {
        name: 'TimelineStory',
        components: {
            Button,
            Badge,
        },
        computed: {
            downloadName () {
                return last(this.story.url.split('/'))
            },
            isVideo () {
                return this.story.type === 'video'
            },
            srcUrl () {
                return `${this.story.url}`
            },
            prettyDateTime () {
                const date = new Date(this.story.taken_at * 1000)
                return {
                    date: date.toLocaleDateString("en-GB"),
                    time: date.toLocaleTimeString("en-GB")
                }
            }
        },
        data () {
            return {
                modal: false,
            }
        },
        mixins: [
            localDatetime
        ],
        props: [
            'story',
        ],
        setup() {
            useTheme({
                noDarkMode: true,
            });

            return {
            }
        }
    }
</script>

<style scoped lang="scss">
    .tic-title {
        display: flex;
        flex-direction: column;

        &__date {
            font-size: 18px;
        }

        &__time {
            font-size: 14px;
            margin-top: 2px;
            margin-left: 1px;
            font-weight: bold;
            color: #71717a;
        }
    }

    .tic-download {
        background-color: #22c0e8;
        font-weight: medium;

        &:hover {
            background-color: #34b4d4;
            color: white;
            text-decoration: none;
        }
    }

    .tic-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 20px;
        position: relative;
        width: 100%;

        &:hover {
            background-color: #e9ebed;
        }

        &:not(:last-child) {
            &::after {
                content: '';
                background-color: #c8c8d1;
                position: absolute;
                width: calc(100% - 26px);
                height: 1px;
                bottom: 0;
            }
        }

        &::before {
            content: ' ';
            background: white;
            display: inline-block;
            position: absolute;
            border-radius: 50%;
            border: 3px solid #22c0e8;
            left: -9px;
            margin-top: 10px;
            top: 0;
            width: 20px;
            height: 20px;
            z-index: 400;
        }
    }
</style>
