<script setup>
import { EyeIcon } from 'lucide-vue';
import Button from '@/components/pfp/ui/button/Button.vue';
import ResultSection from '@/components/pfp/results-table/ResultSection.vue';
import ResultTitle from '@/components/pfp/results-table/ResultTitle.vue';
import GumtreeIcon from '@/components/pfp/icons/GumtreeIcon.vue';
import Label from '../../../ui/label/Label.vue';
import { format } from 'date-fns';

const props = defineProps({
  listings: {
    type: Array,
    required: true,
  },
});

function handleViewMore() {
  window.open('/gumtree', '_blank');
}
</script>

<template>
  <ResultSection v-if="props.listings" class="tw-flex tw-flex-col tw-gap-2">
    <ResultTitle class="tw-flex tw-mb-4">
      <GumtreeIcon class="tw-mr-3" /> Top Gumtree match
    </ResultTitle>
    <div>
      <div v-for="listing in listings" :key="listing.id" class="tw-flex tw-flex-col tw-gap-y-4">
        <div class="tw-flex tw-flex-col md:tw-flex-row tw-gap-4">
          <div class="tw-basis-2/5">
            <Label>
              Listing title
            </Label>
            <p class="tw-text-sm tw-text-muted-foreground">{{ listing.title }}</p>
          </div>
          
          <div>
            <Label>
              Description
            </Label>
            <p class="tw-text-sm tw-text-muted-foreground">{{ listing.description }}</p>
          </div>
        </div>
        <div class="tw-flex tw-flex-col md:tw-flex-row tw-gap-y-4 tw-gap-x-8">
          <div>
            <Label>
              Listing author
            </Label>
            <p class="tw-text-sm tw-text-muted-foreground">{{ listing.author }}</p>
          </div>
          
          <div>
            <Label>
              Author account ID
            </Label>
            <p class="tw-text-sm tw-text-muted-foreground">{{ listing.accountId }}</p>
          </div>

          <div>
            <Label>
              Listing price
            </Label>
            <p class="tw-text-sm tw-text-muted-foreground">{{ listing.price.currency_label || '' }}{{ listing.price.amount }} - {{ listing.price.currency_iso_code }}</p>
          </div>

          <div>
            <Label>
              Listing ID
            </Label>
            <p class="tw-text-sm tw-text-muted-foreground">{{ listing.listingId }}</p>
          </div>
          
          <div>
            <Label>
              Postcode
            </Label>
            <p class="tw-text-sm tw-text-muted-foreground">{{ listing.postcode }}</p>
          </div>

          <div>
            <Label>
              Last time updated
            </Label>
            <p class="tw-text-sm tw-text-muted-foreground">{{ format(new Date(listing.modifiedDate), 'dd/MM/yyyy HH:mm:ss') }}</p>
          </div>
        </div>

        <div class="tw-flex tw-gap-4">
          <a :href="image.extrabig" target="_blank" v-for="image in listing.images" :key="image.thumb">
            <img :src="image.preview" class="tw-w-32 tw-h-32 tw-object-cover tw-rounded-md" />
          </a>
        </div>

        <Button variant="outline" @click="handleViewMore" class=" md:tw-self-end">
          <EyeIcon class="tw-mr-2" /> View more listings on our Gumtree tool
        </Button>
      </div>
    </div>
  </ResultSection>
</template>